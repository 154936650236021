<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
        
            <!-- <div v-if="rolpermisions" class="h-10 w-full flex flex-row px-2 mt-4">
               <div class="h-full w-1/2 p-1">
                <div class="h-full rounded-lg flex flex-row justify-center items-center" :class="'bg-box-'+mode" @click="toTops()">
                    <i class="mdi mdi-filter-variant text-purple"></i>
                </div>
               </div>
               <div class="h-full w-1/2 p-1">
                <div class="h-full rounded-lg flex flex-row justify-center items-center" :class="'bg-box-'+mode" @click="toComments()">
                    <i class="mdi mdi-note-outline text-purple"></i>
                </div>
               </div>
            </div> -->

            <div v-if="rolpermisions" class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2" >

                <div class="h-10 w-10 mx-2 rounded-full bg-red flex flex-row justify-center items-center" @click="toComments()">
                    <i class="mdi mdi-note-outline text-white text-xl font-semibold"></i>
                </div>

                <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="toTops()">
                    <i class="mdi mdi-filter-variant text-white text-xl font-semibold"></i>
                </div>

            </div>

            <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data, loading}">

                <div v-if="!loading" class="h-auto">

                    <div class="h-auto mt-4 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('billing')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.SalesTotal | reduceBigNumbers()}} €</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Sales" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('meters')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MetersTotal | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Meters" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div v-if="rol !== 'Representante'" class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('margin')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MarginTotal| reduceBigNumbers()}} %</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Margin" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('averagePrice')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MediumPriceTotal | reduceBigNumbers()}} €/m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.MediumPrice" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto flex flex-row justify-between items-center px-2 mt-10">
                        <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('unpaid')}}</span>
                        <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.UnpaidTotal| reduceBigNumbers()}} €</span>
                    </div>

                    <div class="h-auto flex flex-row justify-end items-center px-2">
                        <span class="text-xs text-orange">{{data.UnpaidSalesTotal| reduceBigNumbers()}} % / {{$t('billing')}}</span>
                    </div>

                    <div class="h-26 flex flex-col w-full rounded-xl shadow_box" :class="'bg-box-'+mode">

                        <div class="h-full flex flex-row justify-start items-center" >

                            <div class="h-full flex flex-row justify-start items-center w-6/12"></div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">

                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('unpaid')}} {{$t('year')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearantant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearact] | reduceBigNumbers()}} €</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">
                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">% / {{$t('billing')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearantant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearact] | reduceBigNumbers()}}%</span>
                            </div>
                        </div>
                    
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <div class="h-full flex flex-col justify-start items-start">
                                <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('promotionalExpenses')}}</span>
                                <span class="text-xs text-gray">({{$t('last12Months')}})</span>
                            </div>

                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{ data.PromoTotal | reduceBigNumbers()}} € <br>  <span class="text-xs text-gray">{{data.PromoSaleTotal | reduceBigNumbers()}}% / {{$t('billing')}}</span></span>
                        </div>

                        <div class="h-24">
                            <columnchart :data="data.Promo" text_option="promotion" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('orderBook')}}</span>
                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{data.TotalPendingOrderTotal| reduceBigNumbers()}} € <br> 
                                <span class="text-xs text-gray">{{$t('currentValue')}}</span></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.TotalPendingOrder" text_option="orderBook" chartId="chartId" />
                        </div>
                    </div>

                    <All :model="model2" :immediate="true" :config="config" v-slot="{data:data2, loading: loading2}">
                        <div v-if="!loading2" class="h-auto">
                            <div class="h-auto flex flex-row justify-start items-center px-2 mt-10">
                                <span class="text-md font-semibold" :class="'text-dfont-'+mode">
                                    {{ $t('annualSalesHistory') }}
                                </span>
                            </div>
                            <div class="h-24 px-2">
                                <columnchart :data="data2.Client.Historical" unit="€" />
                            </div>
                            <div class="h-auto flex flex-row justify-start items-center px-2 mt-6">
                                <span class="text-md font-semibold" :class="'text-dfont-'+mode">
                                    {{ $t('aboutTheClient') }}
                                </span>
                            </div>
                            <div class="h-10 my-1">
                                <div class="h-full rounded flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                    <span class="text-xs font-semibold" :class="'text-dfont-'+mode">
                                        {{ $t('averagePriceOfYourPurchases') }}
                                    </span>
                                    <span class="font-semibold text-sm" :class="'text-dfont-'+mode">
                                        {{ data2.Client.MediumPrice | reduceBigNumbers() }} €/m<sup>2</sup>
                                    </span>
                                </div>
                            </div>
                            <div class="h-10 my-1">
                                <div class="h-full rounded flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                    <div class="h-full w-5/6 flex flex-col justify-center items-start">
                                        <span class="text-xs font-semibold" :class="'text-dfont-'+mode">
                                            {{ $t('closeLastPurchase') }}
                                        </span>
                                    </div>
                                    <span class="font-semibold text-sm" :class="'text-dfont-'+mode">
                                        {{ data2.Client.LastSale | moment('DD/MM/YYYY') }}
                                    </span>
                                </div>
                            </div>
                            <div class="h-auto px-2 my-1 rounded" :class="'bg-box-'+mode">
                                <div class="h-10 flex flex-row justify-between items-center px-2">
                                    <span class="text-xs font-semibold" :class="'text-dfont-'+mode">
                                        Familia más comprada (€)
                                    </span>
                                </div>
                                <div class="h-auto px-2 max-h-10 overflow-auto">
                                    <div  v-for="(el, index) in data2.Client.mostBoughtFamily"
                                        :key="index" class="h-10 w-full flex flex-row justify-center items-center">
                                        <span class="font-semibold text-sm truncate" :class="'text-dfont-'+mode">
                                            {{ el.Family }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="h-auto px-2 my-1 rounded" :class="'bg-box-'+mode">
                                <div class="h-10 flex flex-row justify-between items-center px-2">
                                    <span class="text-xs font-semibold" :class="'text-dfont-'+mode">
                                        Formatos más comprados (€)
                                    </span>
                                </div>
                                <div class="h-auto px-2 max-h-10 overflow-auto">
                                    <div  v-for="(el, index) in data2.Client.mostBoughtFormat"
                                        :key="index" class="h-10 w-full flex flex-row justify-center items-center">
                                        <span class="font-semibold text-sm truncate" :class="'text-dfont-'+mode">
                                            {{ el.Format }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </All>

                    <div class="h-12"></div>

                </div>

                <div v-else class="h-32 relative">
                    <loader :loading="loading" />
                </div>

            </All>

        </div>

    </div>

</template>

<script>
import { state } from '@/store';
import chartarea from '@/components/chartarea.vue';
import donutchart from '@/components/donutchart.vue';
import columnchart from '@/components/columnchart.vue';
import { All } from '@/api/components';
import Loader from '../../components/loader.vue';

    export default {
        name: 'client',
        components: {
            chartarea,
            donutchart,
            columnchart,
            All,
            Loader
        },
        data() {
            return {
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY')
            }
        },
        methods: {
            toTops() { this.$router.push({ name: 'representantclienttops' }) },
            toComments() { this.$router.push({name: 'comments'}) },
            tofilters() { this.$router.push({name: 'representantfilters'}) }
        },
        computed: {
            rol() { return state.user.rol.Name },
            client() { return state.clientSelected },
            model() {
                if (state.comercialSelected && state.comercialSelected.Id) {
                    return 'KpiclientrepresentantUser'
                }
                else { return 'KpiclientrepresentantZone' }
            },
            model2() {
                if (state.comercialSelected && state.comercialSelected.Id) {
                    return 'KpiclientrepresentantUser2'
                }
                else { return 'KpiclientrepresentantZone2' }
            },
            user() { return state.user },
            config() {
                if (state.comercialSelected && state.comercialSelected.Id) {
                    if (state.clientSelected.Id) {
                        return {
                            data: {
                                period: state.period,
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                IdUser: state.comercialSelected.Id,
                                Client: state.clientSelected.Id,
                                Employee: state.idEmployeeSelected
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                period: state.period,
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                IdUser: state.comercialSelected.Id,
                                Client: state.clientSelected.IdClient,
                                Employee: state.idEmployeeSelected
                            }
                        }
                    }
                }
                else {
                    if (state.clientSelected.Id) {
                        if (state.user.rol.Name !== 'Representante') {
                            return {
                                data: {
                                    period: state.period,
                                    Company: state.idCompany,
                                    SaleType: state.saleTypeSelected,
                                    Zone: state.idZone,
                                    Client: state.clientSelected.Id,
                                    Employee: state.idEmployeeSelected
                                }
                            }
                        }
                        else {
                            return {
                                data: {
                                    period: state.period,
                                    Company: state.idCompany,
                                    Client: state.clientSelected.Id,
                                    Employee: state.idEmployeeSelected
                                }
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                period: state.period,
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                Client: state.clientSelected.IdClient,
                                Employee: state.idEmployeeSelected
                            }
                        }
                    }
                }
            },
            zoneselected() { return state.zoneSelected },
            comercial() { return state.comercialSelected },
            period() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            rol() { return state.user.rol.Name },
            rolpermisions() { return true },
            zona() { return state.zone },
            mode(){
                return state.mode
            },
            query(){
                return {
                    period: state.period,
                    month: state.month,
                    IndustrialGroup: state.industrialGroup
                }
            }
        }
    }

</script>
